import '../../Admin/js/Utilities/utility-functions';
import { createApp } from 'vue';
import axios from 'axios';
import { createPinia } from 'pinia';
import vuetify from '../../Admin/js/Plugins/vuetify';

window.axios = axios;

import App from './App.vue';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(vuetify);

app.mount('#prospus-pro-app');
