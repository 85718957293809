import {defineStore} from "pinia";


export const useLogin = defineStore('LoginStore', {
    state: () => ({
        user_id: null,
        token: null
    }),
    actions: {
        getUserId() {
            return this.user_id
        },
        setUserId(value) {
            this.user_id = value;
        },
        setToken(value) {
            this.token = value;
        },
        getToken() {
            return this.token
        }
    }
});
