<template>
    <BaseModal>
        <template #header>
            <div class="prospus-modal__header--actions">
                <img
                    src="/public/images/kaamfu-wide-k.png"
                    class="prospus-logo"
                    alt="kaamfu-logo-modal"
                />
                <span class="prospus-close" @click="$emit('close')"
                    >&times;</span
                >
            </div>
            <h3 class="prospus-title">Password Was Reset</h3>
        </template>
        <div>
            <div
                class="prospus-form prospus-flex prospus-flex-column prospus-flex-center"
            >
                <div>
                    Your password has been reset. Please sign in using your new
                    password.
                </div>
                <div class="prospus-mt-20">
                    <a class="prospus-btn" @click.stop="$emit('close')">
                        Continue
                    </a>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
<script>
import BaseModal from "../Common/BaseModal.vue";
export default {
    components: {
        BaseModal,
    },
    emits: ["close"],
};
</script>
