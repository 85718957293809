<template>
    <div class="prospus-modal">
        <div ref="refModalContent" class="prospus-modal__content" :class="variant">
            <div class="prospus-modal__header">
                <slot name="header"></slot>
            </div>
             <div ref="refModalBody" class="prospus-modal__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BaseModal',
    props: {
        variant: {
            type: String,
            default: 'prospus-bg-glory-red'
        }
    },
    mounted() {
        const timeoutHandler = setTimeout(() => {
            const modalContent = this.$refs.refModalBody
            const height = modalContent.clientHeight;
            console.log( "modal height : " , height)
            modalContent.style
                && (modalContent.style.height = `${height}px`)
            clearTimeout(timeoutHandler);
        }, 1000)
    }
}
</script>
