<template>
    <div class="prospus-tooltip-wrapper">
        <div class="prospus-tooltip-wrapper__arrow"></div>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "Tooltip",
    }
</script>