export const AGENT = {
    firstname: 'Marc',
    lastname: 'Ragsdale',
    position: 'Business Owner',
    city: 'Portland',
    state: 'OR',
    description: 'Marc is a Business Owner from Portland, OR',
    experience: '20+ years',
    following: '1250',
    work_done: '1000+',
    skills: [
        'Information Technology',
        'Product development',
        'Product consulting'
    ]
}

export const INVOICE_BUTTONS = [
    {
        text: 'Print',
        type: 'default',
        icon: null
    },
    {
        text: 'Download PDF',
        type: 'default',
        icon: null
    },
    {
        text: 'Receipts',
        type: 'default',
        icon: 'arrow_drop_down'
    },
    {
        text: 'Make Payment',
        type: 'divider',
        icon: 'payments'
    }
]

export const INVOICE_TABLE_HEADERS = [
    {
        text: 'ID',
        align: 'left',
        width: '10%',
        value: 'id'
    },
    {
        text: 'Items',
        align: 'left',
        width: '50%',
        value: 'items'
    },
    {
        text: 'Quantity',
        align: '',
        width: '10%',
        value: 'quantity'
    },
    {
        text: 'Price',
        align: 'right',
        width: '15%',
        value: 'price'
    },
    {
        text: 'Amount',
        align: 'right',
        width: '15%',
        value: 'amount'
    }
]