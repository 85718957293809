<template>
    <Tooltip>
        <ul>
            <template v-if="!confirm">
                <li>
                    <span v-if="noOfChars" class="material-icons">done</span>
                    <span v-else class="material-icons">close</span>
                    At least 8 characters
                </li>
                <li>
                    <span v-if="checkSymbolOrNo" class="material-icons"
                        >done</span
                    >
                    <span v-else class="material-icons">close</span>
                    Contains number or symbol
                </li>
            </template>
            <template v-else>
                <li>
                    <span class="material-icons">close</span>
                    Doesn't match with Password
                </li>
            </template>
        </ul>
    </Tooltip>
</template>
<script>
import Tooltip from "./Tooltip.vue";

export default {
    name: "PasswordCriteriaTooltip",
    components: {
        Tooltip,
    },
    props: {
        showTooltip: {
            type: Boolean,
            default: false,
        },
        password: {
            type: String,
            default: "",
        },
        confirm: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["validity"],
    computed: {
        noOfChars() {
            return this.password.length >= 8;
        },
        checkSymbolOrNo() {
            return (
                /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password) ||
                /[0-9]/.test(this.password)
            );
        },
    },
    watch: {
        password: function (no, ov) {
            this.$emit("validity", this.noOfChars && this.checkSymbolOrNo);
        },
    },
};
</script>
